import {
  AfterViewChecked,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AnnotationService,
  BookmarkViewService,
  FormDesignerService,
  FormFieldsService,
  LinkAnnotationService,
  MagnificationService,
  NavigationService,
  PageOrganizerService,
  PdfViewerComponent as pdfc,
  PrintService,
  TextFieldSettings,
  TextSearchService,
  TextSelectionService,
  ThumbnailViewService,
  ToolbarItem,
  ToolbarService,
  ToolbarSettingsModel,
} from '@syncfusion/ej2-angular-pdfviewer';
import { FillableVariable } from '@tremaze/shared/feature/document-editor';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { NotificationService } from '@tremaze/shared/notification';
import { isNotEmpty } from '@tremaze/shared/util-utilities';

@Component({
  selector: 'tremaze-pdf-viewer',
  template: `
    @if (proxyFixedDocumentUrl; as documentUrl) {
      <mat-drawer-container>
        <mat-drawer-content>
          <ejs-pdfviewer
            id="pdfviewer"
            [documentPath]="documentUrl"
            [resourceUrl]="resource"
            [enableDownload]="true"
            [downloadFileName]="documentName"
            [toolbarSettings]="toolbarSettings"
            serviceUrl="https://pdfviewer.tagea.app/api/pdfviewer"
            locale="de-DE"
            style="height:100%;display:block"
            [enablePrint]="true"
            [printScaleRatio]="5"
            (toolbarClick)="onToolbarClick($event)"
          >
          </ejs-pdfviewer>
        </mat-drawer-content>

        <mat-drawer position="end" mode="side" [opened]="showFillableItems">
          <mat-card>
            <mat-card-header>
              <h3 mat-card-title>Platzhalter</h3>
              <mat-card-subtitle
                >Füge die Platzhalter als Namen von Formularfeldern ein, um sie
                ersetzen zu können
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <table class="default-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Platzhalter</th>
                    <th>Wert</th>
                  </tr>
                </thead>
                <tbody>
                  @for (item of fillableVariables; track item.name) {
                    <tr>
                      <th>{{ item.displayName }}</th>
                      <td>{{ item.name }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </mat-card-content>
            <mat-card-actions align="end">
              <button mat-button (click)="fillFields()">
                Platzhalter ausfüllen
              </button>
            </mat-card-actions>
          </mat-card>
        </mat-drawer>
      </mat-drawer-container>
    }
  `,
  styleUrl: './pdf-viewer.component.scss',
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
    FormDesignerService,
    FormFieldsService,
    PageOrganizerService,
  ],
})
export class PdfViewerComponent implements AfterViewChecked {
  @Input({ required: true }) documentUrl?: string;
  @Input({ required: true }) documentName = 'document.pdf';
  @Input() fillableVariables: FillableVariable[] = [];

  private readonly _confirmation = inject(ConfirmationService);
  private readonly _notification = inject(NotificationService);

  private readonly openFillableItem = {
    prefixIcon: 'e-form-field',
    tooltipText: 'Platzhalter',
    text: 'Platzhalter',
    id: 'openFillableItem',
  };

  get toolbarSettings(): ToolbarSettingsModel {
    const defaultItems: ToolbarItem[] = [
      'OpenOption',
      'PageNavigationTool',
      'MagnificationTool',
      'PanTool',
      'SelectionTool',
      'SearchOption',
      'PrintOption',
      'DownloadOption',
      'UndoRedoTool',
      'AnnotationEditTool',
      'FormDesignerEditTool',
      'CommentTool',
    ];

    return {
      toolbarItems: [
        ...(this.fillableVariables?.length ? [this.openFillableItem] : []),
        ...defaultItems,
      ],
      showTooltip: true,
    };
  }

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  private _initialized = false;

  @ViewChild(pdfc) pdfViewer?: pdfc;

  readonly resource = `${this.document.location.protocol}//${this.document.location.host}/assets/ej2-pdfviewer-lib`;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewChecked() {
    if (!this._initialized && this.pdfViewer?.toolbar) {
      this._initialized = true;
      (this.pdfViewer.documentLoad as EventEmitter<any>).subscribe(() => {
        const field = this._findFirstFillableFieldWithEmptyValue();
        if (field) {
          this._confirmation
            .askUserForConfirmation({
              title: 'Automatisches Ausfüllen',
              text: 'Wir haben automatisch ausfüllbare Felder in diesem Dokument gefunden. Möchten Sie diese automatisch ausfüllen lassen?',
              confirmButtonText: 'Ja',
              denyButtonText: 'Nein',
            })
            .subscribe((r) => {
              if (r.confirmed) {
                this.fillFields();
              }
            });
        }
      });
    }
  }

  private _showFillableItems = false;

  get showFillableItems() {
    return this._showFillableItems;
  }

  onToolbarClick(ev: { item: { id: string } }) {
    if (ev?.item?.id === 'openFillableItem') {
      this._showFillableItems = !this._showFillableItems;
    }
  }

  private _findFirstFillableFieldWithEmptyValue() {
    if (this.fillableVariables.length) {
      return this.pdfViewer?.formFields.find((f) => {
        const name = f.name;
        const fillableVariable = this.fillableVariables.find(
          (v) => v.name === name,
        );
        if (!fillableVariable || !isNotEmpty(fillableVariable.value)) {
          return false;
        }
        if (f.value === null || f.value === undefined || f.value === '') {
          return true;
        }
        return false;
      });
    }
    return undefined;
  }

  fillFields() {
    this._showFillableItems = false;
    let filledOutCount = 0;
    this.fillableVariables.forEach((v) => {
      const field = this.pdfViewer?.formFields.find((f) => f.name === v.name);
      const fieldValue = field?.value;
      const value = v?.value ?? '';
      if (
        field &&
        fieldValue !== value &&
        (fieldValue === null || fieldValue === undefined || fieldValue === '')
      ) {
        this.pdfViewer?.formDesignerModule.updateFormField(field.id!, {
          value: value,
        } as TextFieldSettings);
        filledOutCount++;
      }
    });

    this._notification.showNotification(
      `Es wurden ${filledOutCount} Felder automatisch ausgefüllt.`,
    );
  }

  async save() {
    if (!this.pdfViewer) {
      return;
    }
    const blob = await this.pdfViewer.saveAsBlob();
    return new Blob([blob], { type: 'application/pdf' });
  }

  print() {
    if (!this.pdfViewer) {
      return;
    }
    this.pdfViewer.print.print();
  }
}
